.container {
  width: 100%;
  height: auto;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 30px;
  @media screen and (max-width: 1550px) {
    padding-top: 25px;
  }
  @media screen and (max-width: 1000px) {
    width: 95%;
    margin: 0 auto;
    text-align: center;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
  .title {
    width: fit-content;
    position: relative;
    padding: 10px 40px;
    @media screen and (max-width: 1550px) {
      padding: 7px 25px;
      margin-bottom: 10px;
    }
    h2 {
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      font-size: 50px;
      @media screen and (max-width: 1550px) {
        font-size: 45px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 30px;
        line-height: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 25px;
        padding: 5px;
        line-height: 25px;
      }
    }
    img {
      position: absolute;
      &:nth-child(2) {
        top: 0;
        left: 0;
        @media screen and (max-width: 1350px) {
          width: 25px;
        }
      }
      &:nth-child(3) {
        bottom: 0;
        right: 0;
        @media screen and (max-width: 1350px) {
          width: 25px;
        }
      }
    }
  }
  p {
    -webkit-text-size-adjust: none;
    font-size: 17px;
    text-align: center;
    &:nth-child(3) {
      font-weight: 700;
    }
    span {
      font-weight: 700;
    }
    a {
      color: #fff;
    }
    @media screen and (max-width: 1550px) {
      font-size: 15px;
      margin-bottom: 10px;
      &:nth-child(2) {
        margin-bottom: 0px;
      }
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
      padding: 0 21px;
    }
  }
  .teamGrid {
    width: 95%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 1550px) {
      margin-top: 10px;
    }
    img {
      width: 20%;
      @media screen and (max-width: 768px) {
        width: 50%;
      }
    }
    .deco {
      position: absolute;
      width: 103%;
      bottom: -10%;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to top,
    rgba(16, 18, 52, 0.7),
    rgba(42, 42, 123, 0.7)
  );
  box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
  -moz-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
  border-radius: 20px;
  width: 93%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px;
  margin: 50px auto;
  @media screen and (max-width: 1000px) {
    margin: 30px auto;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  div {
    padding: 10px;
    width: calc(100% / 3);
    @media screen and (max-width: 768px) {
      padding-bottom: 10px;
    }
    p {
      font-weight: 700;
      text-align: center;
      font-size: 25px;
      @media screen and (max-width: 1550px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 18px;
      }
      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
      span {
        color: #fff;
      }
    }
  }
}
button {
  margin: 0 20px;
  border: 0;
  outline: none;
  font-family: "Kanit", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 15px;
  column-gap: 10px;
  cursor: pointer;
  height: 55px;
  transition: all 0.4s;
  color: rgb(58, 54, 131);
  background-color: #fff;
  &:hover {
    box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
  }
  @media screen and (max-width: 1550px) {
    font-size: 16px;
    height: 50px;
  }
  @media screen and (max-width: 1350px) {
    font-size: 13px;
    height: 40px;
    padding: 10px;
    width: 170px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 13px;
    height: 40px;
    padding: 0px;
    width: 170px;
  }
  @media screen and (max-width: 820px) {
    column-gap: 5px;
    font-size: 11px;
    height: 40px;
    padding: 8px;
    width: 160px;
    padding: 5px;
  }
  img {
    height: 100%;
    @media screen and (max-width: 1000px) {
      height: 60%;
      width: auto;
      margin-right: 5px;
    }
    @media screen and (max-width: 800px) {
      height: 60%;
      width: 27px;
    }
  }
  // &:nth-child(2) {
  //   img {
  //     filter: invert(18%) sepia(42%) saturate(2685%) hue-rotate(226deg)
  //       brightness(30%) contrast(94%);
  //   }
  // }
}
.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    to top,
    rgba(16, 18, 52, 0.7),
    rgba(42, 42, 123, 0.7)
  );
  box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
  -moz-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
  border-radius: 20px;
  width: 93%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px;
  margin: 50px auto;
  @media screen and (max-width: 1000px) {
    margin: 30px auto;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  div {
    padding: 10px;
    width: calc(100% / 3);
    @media screen and (max-width: 768px) {
      padding-bottom: 10px;
    }
    p {
      font-weight: 700;
      text-align: center;
      font-size: 25px;
      @media screen and (max-width: 1550px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 18px;
      }
      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
      span {
        color: #fff;
      }
    }
  }
  button {
    margin: 0 20px;
    border: 0;
    outline: none;
    font-family: "Kanit", sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 15px;
    column-gap: 10px;
    cursor: pointer;
    height: 55px;
    transition: all 0.4s;
    color: rgb(58, 54, 131);
    background-color: #fff;
    &:hover {
      box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
    }
    @media screen and (max-width: 1550px) {
      font-size: 16px;
      height: 50px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
      height: 40px;
      padding: 10px;
      width: 170px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
      height: 40px;
      padding: 0px;
      width: 170px;
    }
    @media screen and (max-width: 820px) {
      column-gap: 5px;
      font-size: 11px;
      height: 40px;
      padding: 8px;
      width: 160px;
      padding: 5px;
    }
    img {
      height: 100%;
      @media screen and (max-width: 1000px) {
        height: 60%;
        width: auto;
        margin-right: 5px;
      }
      @media screen and (max-width: 800px) {
        height: 60%;
        width: 27px;
      }
    }
    // &:nth-child(2) {
    //   img {
    //     filter: invert(18%) sepia(42%) saturate(2685%) hue-rotate(226deg)
    //       brightness(30%) contrast(94%);
    //   }
    // }
  }
}

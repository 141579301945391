@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Lato:wght@400;700&family=Rubik:wght@700&display=swap");
body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}
/* font-family: 'Kanit', sans-serif;
font-family: 'Lato', sans-serif;
font-family: 'Rubik', sans-serif; */

.container {
  height: auto;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  //background: url("../../assets/LiveTrackerImages/VaultLiveBG2.jpg") no-repeat;
  background-size: 100% auto;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  text-transform: capitalize;
  padding: 80px 200px;
  margin: 120px 0 70px 50%;
  //box-shadow: 0px 0px 40px 15px rgba(0, 0, 0, 0.479);
  @media screen and (max-width: 1450px) {
    padding: 80px 25px;
  }
  @media screen and (max-width: 900px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 768px) {
    background-size: 250% 100%;
    padding: 40px 20px 80px 20px;
  }
  & > div {
    width: 50%;
    &:nth-child(2) {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .redDot {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      transform: translateY(-80%);
      @media screen and (max-width: 1350px) {
        width: 25px;
        height: 25px;
      }
      @media screen and (max-width: 768px) {
        width: 22px;
        height: 22px;
      }
    }
    p {
      text-align: center;
      font-size: 50px;
      font-weight: 700;
      @media screen and (max-width: 1550px) {
        font-size: 45px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 25px;
      }
      span {
        color: rgb(16, 248, 16);
        font-size: 55px;
        @media screen and (max-width: 1550px) {
          font-size: 50px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 35px;
        }
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
      }
    }
  }
  .table {
    background: linear-gradient(
      to top,
      rgba(16, 18, 52, 0.7),
      rgba(42, 42, 123, 0.7)
    );
    box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
    -webkit-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
    -moz-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
    border-radius: 20px;
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin: 50px auto;
    @media screen and (max-width: 1000px) {
      margin: 30px auto;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    div {
      padding: 10px;
      width: calc(100% / 3);
      @media screen and (max-width: 768px) {
        padding-bottom: 10px;
      }
      p {
        font-weight: 700;
        text-align: center;
        font-size: 25px;
        @media screen and (max-width: 1550px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 18px;
        }
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
        span {
          color: #fff;
        }
      }
    }
  }
  .cardsTable {
    position: relative;
    background: linear-gradient(
      to top,
      rgba(16, 18, 52, 0.7),
      rgba(42, 42, 123, 0.7)
    );
    box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
    -webkit-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
    -moz-box-shadow: 0px 0px 57px -7px rgba(0, 0, 0, 0.87);
    border-radius: 40px;
    padding: 20px;
    @media screen and (max-width: 1350px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 425px) {
      padding: 20px;
    }
    .title {
      position: relative;
      width: fit-content;
      padding: 10px 40px;
      margin: 30px auto;
      @media screen and (max-width: 1550px) {
        padding: 7px 25px;
      }
      @media screen and (max-width: 425px) {
        margin: 15px auto;
      }
      h2 {
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-size: 40px;
        text-transform: uppercase;
        @media screen and (max-width: 1550px) {
          font-size: 35px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 20px;
        }
        @media screen and (max-width: 768px) {
          font-size: 18px;
          padding: 5px;
        }
      }
      img {
        position: absolute;
        &:nth-child(2) {
          top: 0;
          left: 0;
          width: 25px;
          @media screen and (max-width: 1350px) {
            width: 15px;
          }
        }
        &:nth-child(3) {
          bottom: 0;
          right: 0;
          width: 25px;
          @media screen and (max-width: 1350px) {
            width: 15px;
          }
        }
      }
    }
    .grid {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      & > div {
        width: calc(100% / 2);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
        @media screen and (max-width: 600px) {
          width: 50%;
        }
        img {
          width: 60%;
        }
        span {
          font-size: 20px;
          position: relative;
          @media screen and (max-width: 1550px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 16px;
          }
          &:nth-child(2) {
            color: rgb(120, 121, 138);
            font-weight: 700;
          }
        }
      }
    }
    .hint {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: -40px;
      transform: translateX(-50%);
      left: 50%;
      font-weight: 700;
      font-size: 25px;
      text-transform: none;
      @media screen and (max-width: 1550px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
        bottom: -30px;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
    }
    button {
      margin: 0 20px;
      border: 0;
      outline: none;
      font-family: "Kanit", sans-serif;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      border-radius: 15px;
      column-gap: 10px;
      cursor: pointer;
      height: 55px;
      transition: all 0.4s;
      color: rgb(58, 54, 131);
      background-color: #fff;
      &:hover {
        box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
      }
      @media screen and (max-width: 1550px) {
        font-size: 16px;
        height: 50px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
        height: 40px;
        padding: 10px;
        width: 170px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 13px;
        height: 40px;
        padding: 0px;
        width: 170px;
      }
      @media screen and (max-width: 820px) {
        column-gap: 5px;
        font-size: 11px;
        height: 40px;
        padding: 8px;
        width: 160px;
        padding: 5px;
      }
      img {
        height: 100%;
        @media screen and (max-width: 1000px) {
          height: 60%;
          width: auto;
          margin-right: 5px;
        }
        @media screen and (max-width: 800px) {
          height: 60%;
          width: 27px;
        }
      }
      // &:nth-child(2) {
      //   img {
      //     filter: invert(18%) sepia(42%) saturate(2685%) hue-rotate(226deg)
      //       brightness(30%) contrast(94%);
      //   }
      // }
    }
  }
  .underBtnsText {
    width: 90%;
    margin: 0 auto 50px auto;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    text-transform: None;
    @media screen and (max-width: 1550px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1000px) {
      margin: 0 auto 30px auto;
    }
  }
}
.red {
  color: red !important;
}
.green {
  color: rgb(16, 248, 16) !important;
}
.white {
  color: white !important;
}

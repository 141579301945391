.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #211d4b86;
  border-radius: 50px;
  margin: 20px auto;
  flex-wrap: wrap;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
    0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
  padding: 15px;
  @media screen and (max-width: 1440px) {
    width: 80%;
    padding: 25px 20px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  .text {
    width: 47%;
    padding: 30px 0;
    @media screen and (max-width: 900px) {
      width: 100%;
      text-align: center;
    }
    .title {
      position: relative;
      padding: 10px 0;
      margin-bottom: 10px;
      @media screen and (max-width: 1550px) {
        padding: 7px 25px;
      }
      @media screen and (max-width: 1000px) {
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      h2 {
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
        padding: 5px 0 0 20px;
        @media screen and (max-width: 1550px) {
          font-size: 45px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 30px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 25px;
          padding: 5px;
          line-height: 25px;
        }
      }
      img {
        position: absolute;
        @media screen and (max-width: 1000px) {
          width: 30px;
        }
        &:nth-child(1) {
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          bottom: -5px;
          left: 78%;
          @media screen and (max-width: 1700px) {
            right: 0;
            left: auto;
          }
        }
      }
    }
    p {
      margin-top: 15px;
      font-size: 17px;
      @media screen and (max-width: 1550px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
      }
    }
    h6 {
      margin-top: 15px;
      font-size: 19px;
      @media screen and (max-width: 1550px) {
        font-size: 17px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 15px;
      }
    }
  }
  .preview {
    position: relative;
    width: 47%;
    height: 100%;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    .vault {
      width: 100%;
    }
    .oval {
      position: absolute;
      width: 32%;
      top: 44%;
      left: 53.4%;
      transform: translate(-50%, -50%);
    }
  }
  // & > img {
  //   width: 47%;
  //   @media screen and (max-width: 900px) {
  //     width: 100%;
  //   }
  // }
}

.container {
  width: 100%;
  height: auto;
  padding: 50px 0 0 0;
  position: relative;
  @media screen and (max-width: 1000px) {
    width: 95%;
    margin: 0 auto;
  }
  @media screen and (max-width: 850px) {
    padding-top: 30px;
  }
  @media screen and (max-width: 425px) {
    padding-top: 130px;
    width: 100%;
  }

  .modalContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: rgba(0, 0, 0, 0.705);
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal {
      width: 600px;
      height: 400px;
      background-color: darkgrey;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
    .text {
      padding: 25px 40px;
      width: 50%;
      position: relative;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        padding: 5px 21px;
      }
      .title {
        width: fit-content;
        position: relative;
        padding: 10px 0;
        margin-bottom: 10px;
        @media screen and (max-width: 1650px) {
          padding: 7px 25px;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        @media screen and (max-width: 426px) {
          position: absolute;
          top: -450px;
          padding: 5px;
          width: 90%;
          left: 50%;
          transform: translateX(-50%);
        }
        @media screen and (max-width: 376px) {
          top: -420px;
        }
        @media screen and (max-width: 321px) {
          top: -370px;
        }
        h2 {
          font-family: "Kanit", sans-serif;
          font-weight: 700;
          font-size: 50px;
          line-height: 50px;
          padding: 6px 0 0 20px;
          @media screen and (max-width: 1650px) {
            font-size: 45px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 30px;
            line-height: 30px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 25px;
            padding: 5px;
            line-height: 25px;
          }
        }
        img {
          position: absolute;
          @media screen and (max-width: 1000px) {
            width: 30px;
          }
          &:nth-child(1) {
            top: 0;
            left: 0;
          }
          &:nth-child(2) {
            bottom: -5%;
            right: -5%;
            @media screen and (max-width: 768px) {
              right: 0;
            }
          }
        }
      }
      hr {
        width: 25%;
        background-color: #fff;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      p {
        margin: 15px 0;
        font-size: 17px;
        &:last-child {
          font-weight: 700;
        }
        @media screen and (max-width: 1650px) {
          font-size: 15px;
          margin: 10px 0;
        }
        @media screen and (max-width: 1440px) {
          font-size: 13px;
          margin: 8px 0;
        }
      }
    }
    .preview {
      position: relative;
      width: 50%;
      height: 100%;
      cursor: pointer;
      @media screen and (max-width: 800px) {
        width: 60%;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      .computer {
        width: 100%;
      }
      .msg {
        position: absolute;
        top: 37%;
        left: 50.5%;
        transform: translate(-50%, -50%);
        width: 70%;
        &:nth-child(3) {
          left: 49.5%;
        }
      }
      .playbtn {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 52.5%;
        top: 56%;
        width: 30%;
      }
    }
  }
  .previewCards {
    width: 98%;
    height: 630px;
    display: flex;
    justify-content: space-between;
    margin: 50px auto 20px auto;
    @media screen and (max-width: 1650px) {
      height: 580px;
    }
    @media screen and (max-width: 1350px) {
      width: 95%;
    }
    @media screen and (max-width: 980px) {
      flex-wrap: wrap;
      justify-content: center;
      height: auto;
    }
    @media screen and (max-width: 780px) {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      row-gap: 20px;
      width: 31%;
      height: 100%;
      background-color: #211d4b69;
      border-radius: 25px;
      padding: 25px;
      box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
        0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
      @media screen and (max-width: 980px) {
        width: 40%;
        margin-bottom: 20px;
        row-gap: 0;
        height: 570px;
      }
      @media screen and (max-width: 768px) {
        width: 50%;
      }
      @media screen and (max-width: 600px) {
        width: 90%;
        height: 480px;
      }
      @media screen and (max-width: 375px) {
        height: 600px;
      }
      &:nth-child(1) {
        @media screen and (max-width: 980px) and (min-width: 780px) {
          margin-right: 20px;
        }
      }
      .text {
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 15px;
        //padding: 25px;
        p {
          font-size: 17px;
          @media screen and (max-width: 1650px) {
            font-size: 15px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 13px;
          }
          &.bold {
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            @media screen and (max-width: 1650px) {
              font-size: 15px;
            }
            span {
              font-size: 28px;
              @media screen and (max-width: 1650px) {
                font-size: 24px;
              }
            }
          }
        }
      }
      button {
        background-color: #fff;
        border: 0;
        outline: none;
        font-size: 17px;
        text-transform: uppercase;
        padding: 10px 13px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        color: #353180;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        transition: all 0.3s;
        @media screen and (max-width: 1650px) {
          font-size: 13px;
        }
        &:hover {
          box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
        }
      }
      .cards {
        height: 40%;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 980px) {
          margin-top: 10px;
        }
        .image {
          width: 50%;
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @media screen and (max-width: 768px) {
            padding: 5px;
            text-align: left;
          }
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(6) {
            padding-left: 20px;
            border-left: 2px solid rgba(255, 255, 255, 0.3);
          }
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          }
          @media screen and (max-width: 1650px) {
            font-size: 14px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 10px;
          }
          img {
            width: 35%;
            margin-right: 10px;
          }
        }
      }
    }
  }
  & > p {
    margin: 40px 0;
    text-align: center;
    font-size: 17px;
    line-height: 35px;
    -webkit-text-size-adjust: none;
    span {
      font-weight: 700;
    }
    @media screen and (max-width: 1650px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
      line-height: 20px;
      margin: 20px 0;
    }
    @media screen and (max-width: 768px) {
      margin: 30px 0;
      line-height: 20px;
      padding: 0 21px;
    }
  }
  .tableContainer {
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
      0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
    border: 3px solid rgb(21, 32, 92);
    margin: 20px auto 0 auto;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    height: 200px;
    overflow: hidden;
    font-size: 17px;
    @media screen and (max-width: 1650px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 13px;
    }
    @media screen and (max-width: 980px) {
      width: 85%;
      height: 180px;
    }
    @media screen and (max-width: 768px) {
      height: 500px;
      width: 80%;
    }
    @media screen and (max-width: 426px) {
      width: 90%;
    }
    .title {
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      line-height: 35px;
      background-color: #211d4b69;
      width: 100%;
      backdrop-filter: blur(5px);
      text-align: center;
      font-size: 19px;
      font-weight: 700;
      @media screen and (max-width: 1650px) {
        font-size: 17px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 15px;
      }
      @media screen and (max-width: 768px) {
        height: 15%;
        line-height: 20px;
        padding: 0 10px;
      }
      hr {
        width: 60%;
        border: 0;
        border-top: 2px solid rgba(255, 255, 255, 0.295);
      }
    }
    .table {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70%;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        height: 85%;
      }
      .tile {
        width: 25%;
        height: 100%;
        @media screen and (max-width: 768px) {
          width: 100%;
          height: 25%;
        }
        div {
          width: 100%;
        }
        & > div:nth-child(1) {
          height: 30%;
          background-color: #211d4b69;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 3px solid rgb(21, 32, 92);
        }
        div:nth-child(2) {
          height: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
          & > div {
            height: 80%;
            border-right: 3px solid rgb(65, 87, 196);
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 768px) {
              border: 0;
            }
          }
        }
        &:last-child {
          div:nth-child(2) {
            & > div {
              border: 0;
            }
          }
        }
      }
    }
  }
  .underTable {
    -webkit-text-size-adjust: none;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
      0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
    border: 3px solid rgb(21, 32, 92);
    width: 65%;
    margin: 0 auto;
    background-color: #211d4b69;
    padding: 15px 30px;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    margin-top: -2.5px;
    @media screen and (max-width: 1650px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 13px;
    }
    @media screen and (max-width: 980px) {
      width: 78%;
    }
    @media screen and (max-width: 768px) {
      width: 70%;
    }
    @media screen and (max-width: 426px) {
      width: 80%;
      padding: 10px 15px;
    }
    p {
      text-align: left;
      line-height: 25px;
      span,
      &::first-line {
        font-weight: 700;
        font-size: 19px;
        @media screen and (max-width: 1650px) {
          font-size: 17px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 15px;
          line-height: 15px;
        }
        @media screen and (max-width: 768px) {
          font-size: 13px;
          line-height: 7px;
        }
      }
    }
    button {
      background-color: #fff;
      border: 0;
      outline: none;
      font-size: 17px;
      text-transform: uppercase;
      padding: 10px 13px;
      border-radius: 10px;
      cursor: pointer;
      font-weight: 700;
      color: #353180;
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      transition: all 0.3s;
      @media screen and (max-width: 1650px) {
        font-size: 13px;
      }
      &:hover {
        box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
      }
    }
  }
  .social {
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
      flex-direction: column;
    }
    a {
      width: 49%;
      cursor: pointer;
      transition: all 0.2s;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      &:hover {
        transform: scale(1.03);
      }
      img {
        width: 100%;
      }
    }
  }
  .modalContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: rgba(0, 0, 0, 0.705);
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    animation-delay: 0.5s;
    opacity: 0;
    @media screen and (max-width: 1000px) and (min-height: 550px) {
      align-items: center;
    }
    .clickAway {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
    .modal {
      height: calc(60vw / 1.512273212379936);
      width: 60vw;
      background: url("../../assets/Tvscreen/MDVScreen.png") no-repeat;
      background-size: 100% auto;
      background-position: bottom;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media screen and (max-width: 1000px) and (max-height: 540px) {
        width: 50vw;
        margin-bottom: 5vw;
      }
      @media screen and (max-width: 1000px) and (min-height: 550px) {
        width: 80vw;
        height: calc(80vw / 1.512273212379936);
      }
      .video {
        position: relative;
        width: 87%;
        height: 68%;
        margin-bottom: 7%;
        @media screen and (max-width: 1000px) and (max-height: 540px) {
          margin-bottom: 0;
          margin-top: 6%;
          height: 55%;
        }
      }
      button {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        outline: none;
        @media screen and (max-width: 1000px) and (max-height: 540px) {
          top: 20%;
          right: -10%;
        }
        @media screen and (max-width: 600px) {
          width: 30px;
          height: 30px;
          top: -20px;
          right: -20px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
.fadeOut {
  animation: fadeout 0.5s;
  opacity: 0;
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  height: auto;
  margin: 0 0 100px 0;
  padding: 150px 0 0 0;
  h1 {
    text-align: center;
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 25px;
      padding: 5px;
      line-height: 25px;
    }
  }
  h3 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 20px;
    @media screen and (max-width: 1550px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 12px;
    }
  }
  ul {
    margin-bottom: 20px;
    margin-left: 30px;
    li {
      margin: 5px 0;
    }
  }
}

.container {
  width: 100%;
  height: auto;
  padding: 0 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 50px;
  @media screen and (max-width: 820px) {
    padding-bottom: 40px;
    padding-top: 10px;
  }
  .title {
    width: fit-content;
    position: relative;
    padding: 10px 40px;
    @media screen and (max-width: 1550px) {
      padding: 7px 25px;
    }
    @media screen and (max-width: 1000px) {
      margin-bottom: 20px;
    }
    h2 {
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      font-size: 50px;
      @media screen and (max-width: 1550px) {
        font-size: 45px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 25px;
        padding: 5px;
        line-height: 25px;
      }
    }
    img {
      position: absolute;
      &:nth-child(2) {
        top: 0;
        left: 0;
        @media screen and (max-width: 1350px) {
          width: 30px;
        }
      }
      &:nth-child(3) {
        bottom: 0;
        right: 0;
        @media screen and (max-width: 1350px) {
          width: 30px;
        }
      }
    }
  }
  .grid {
    width: 70vw;
    height: calc(70vw / 2.090909090909091);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    @media screen and (max-width: 1024px) {
      width: 85vw;
      height: calc(85vw / 2.090909090909091);
    }
    @media screen and (max-width: 820px) {
      height: auto;
      flex-direction: column;
    }

    & > div {
      position: relative;
      width: calc(100% / 7);
      @media screen and (max-width: 820px) {
        width: 30%;
      }
      @media screen and (max-width: 650px) {
        width: 40%;
      }
      @media screen and (max-width: 500px) {
        width: 50%;
      }
      @media screen and (max-width: 425px) {
        width: 60%;
      }
      &:nth-child(1) {
        height: 65%;
        @media screen and (max-width: 820px) {
          height: 350px;
        }
        .icon {
          top: 5%;
        }
        .line {
          left: 86%;
          height: 70%;
          top: 35%;
          @media screen and (max-width: 820px) {
            left: 27%;
            height: 105%;
          }
        }
        .text {
          width: 90%;
          padding-bottom: 100px;
          top: -4%;
          @media screen and (min-width: 2100px) {
            padding-bottom: 150px;
            top: 5%;
          }
          @media screen and (max-width: 1200px) and (min-width: 768px) {
            top: -12%;
            width: 100%;
          }
          @media screen and (max-width: 1100px) {
            padding-bottom: 70px;
            left: 51%;
          }
          @media screen and (max-width: 1024px) and (min-width: 426px) {
            top: -15%;
          }
          @media screen and (max-width: 820px) and (min-width: 426px) {
            top: 0;
          }
        }
      }
      &:nth-child(2) {
        height: 100%;
        @media screen and (max-width: 820px) {
          height: 350px;
        }
        .icon {
          bottom: 0;
          left: 45%;
          @media screen and (max-width: 820px) {
            top: 10%;
          }
        }
        .line {
          left: 23%;
          height: 71%;
          top: 14%;
          @media screen and (max-width: 820px) {
            left: -1%;
            top: 50%;
            height: 100%;
          }
        }
        .text {
          width: 95%;
          left: 50%;
          padding-bottom: 140px;
          bottom: 75px;
          @media screen and (min-width: 2100px) {
            bottom: 20%;
            padding-bottom: 150px;
          }
          @media screen and (max-width: 1200px) {
            padding-bottom: 70px;
          }
          @media screen and (max-width: 1024px) {
            padding-bottom: 55px;
          }
          @media screen and (max-width: 820px) {
            top: 0;
            bottom: auto;
            padding-bottom: 100px;
          }
        }
      }
      &:nth-child(3) {
        height: 100%;
        @media screen and (max-width: 820px) {
          height: 350px;
        }
        .icon {
          left: 60%;
          @media screen and (max-width: 820px) {
            top: 10%;
            left: 50%;
          }
        }
        .line {
          height: 57%;
          left: 65%;
          top: 13.5%;
          @media screen and (max-width: 820px) {
            left: 9%;
            top: 30%;
            height: 110%;
          }
        }
        .text {
          top: 100px;
          left: 56%;
          width: 110%;
          padding-top: 120px;
          @media screen and (min-width: 2100px) {
            top: 20%;
            width: 100%;
            left: 54%;
          }
          @media screen and (max-width: 1200px) {
            padding-top: 50px;
          }
          @media screen and (max-width: 1024px) {
            top: 15%;
            padding-top: 80px;
          }
          @media screen and (max-width: 820px) {
            top: 0;
            padding-bottom: 100px;
            padding-top: 15px;
            width: 90%;
            left: 46%;
          }
        }
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        height: 100%;
        @media screen and (max-width: 820px) {
          height: 350px;
        }
      }
      &:nth-child(4) {
        .icon {
          bottom: 15%;
          @media screen and (max-width: 820px) {
            top: 10%;
          }
        }
        .line {
          left: 55%;
          top: 25%;
          height: 46%;
          @media screen and (max-width: 820px) {
            left: 5.5%;
            height: 100%;
            top: 50%;
          }
        }
        .text {
          bottom: 4%;
          width: 160%;
          padding-top: 50px;
          @media screen and (min-width: 2100px) {
            bottom: 10%;
            padding-top: 80px;
            width: 140%;
          }
          @media screen and (max-width: 1700px) {
            bottom: -5%;
          }
          @media screen and (max-width: 1200px) {
            bottom: -13%;
            width: 180%;
          }
          @media screen and (max-width: 1024px) {
            bottom: -5%;
            padding-top: 30px;
            width: 170%;
          }
          @media screen and (max-width: 900px) {
            bottom: -10%;
            padding-top: 30px;
            width: 170%;
          }
          @media screen and (max-width: 820px) {
            top: 1%;
            bottom: auto;
            padding-bottom: 80px;
            padding-top: 15px;
            width: 140%;
            padding-bottom: 30px;
          }
        }
      }
      &:nth-child(5) {
        .icon {
          top: 15%;
          @media screen and (max-width: 820px) {
            top: 10%;
          }
        }
        .line {
          left: 82%;
          top: 25%;
          height: 51%;
          @media screen and (max-width: 820px) {
            left: 32%;
            height: 100%;
            top: 50%;
          }
        }
        .text {
          top: 4%;
          width: 200%;
          padding-bottom: 50px;
          @media screen and (min-width: 2100px) {
            top: 7%;
            width: 150%;
            padding-bottom: 70px;
          }
          @media screen and (max-width: 1700px) {
            top: -5%;
          }
          @media screen and (max-width: 1200px) {
            top: -10%;
            padding-bottom: 40px;
            width: 220%;
          }
          @media screen and (max-width: 1024px) {
            top: -2%;
            padding-bottom: 30px;
            width: 220%;
          }
          @media screen and (max-width: 950px) {
            top: -7%;
            padding-bottom: 30px;
            width: 220%;
          }
          @media screen and (max-width: 768px) and (min-width: 426px) {
            width: 250%;
            //bottom: -17%;
          }
          @media screen and (max-width: 820px) {
            width: 145%;
            top: -3%;
          }
        }
      }
      &:nth-child(6) {
        .icon {
          bottom: 10%;
          @media screen and (max-width: 820px) {
            top: 10%;
          }
        }
        .line {
          left: 88%;
          top: 46%;
          height: 29%;
          @media screen and (max-width: 820px) {
            height: 100%;
            left: 17%;
          }
        }
        .text {
          bottom: 4%;
          padding-top: 60px;
          width: 180%;
          left: 50%;
          @media screen and (min-width: 2100px) {
            width: 150%;
            bottom: 8%;
            padding-top: 80px;
          }
          @media screen and (max-width: 1700px) {
            bottom: -3%;
          }
          @media screen and (max-width: 1200px) {
            bottom: -5%;
            padding-top: 40px;
          }
          @media screen and (max-width: 1024px) {
            bottom: -1%;
            padding-top: 30px;
          }
          @media screen and (max-width: 950px) {
            bottom: -5%;
            padding-top: 30px;
          }
          @media screen and (max-width: 820px) {
            top: 0;
            bottom: auto;
            padding-bottom: 80px;
            padding-top: 15px;
            width: 120%;
          }
        }
      }
      &:nth-child(7) {
        height: 65%;
        @media screen and (max-width: 820px) {
          height: 300px;
        }
        .icon {
          top: 18%;
          @media screen and (max-width: 820px) {
            top: 5%;
          }
        }
        .text {
          top: 4%;
          width: 180%;
          left: 50%;
          padding-bottom: 50px;
          @media screen and (min-width: 2100px) {
            width: 150%;
            top: 10%;
          }
          @media screen and (max-width: 1700px) {
            top: -5%;
          }
          @media screen and (max-width: 1200px) {
            top: -20%;
          }
          @media screen and (max-width: 1024px) {
            top: -4%;
            padding-bottom: 30px;
          }
          @media screen and (max-width: 950px) and (min-width: 426px) {
            top: -12%;
            padding-bottom: 30px;
          }
          @media screen and (max-width: 820px) {
            width: 120%;
          }
        }
      }
      .icon {
        width: 150%;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        z-index: 3;
      }
      .line {
        position: absolute;
        z-index: 1;
      }
      .text {
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #211d4b86;
        padding: 15px 13px;
        border-radius: 25px;
        box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
          0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
        font-weight: 700;
        text-align: center;
        width: 100%;
        font-size: 17px;
        @media screen and (max-width: 1550px) {
          font-size: 15px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 13px;
        }
        @media screen and (max-width: 1024px) {
          padding: 10px;
        }
      }
    }
  }
}

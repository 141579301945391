.container {
  width: 100%;
  height: auto;
  margin-top: 70px;
  padding: 100px 0 50px 0;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    padding-top: 0px;
    padding-bottom: 20px;
    width: 91%;
    margin: 70px auto 0 auto;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    padding-bottom: 100px;
    padding: 0px 20px 50px 20px;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
  .formContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: rgba(0, 0, 0, 0.705);
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    @media screen and (max-width: 700px) {
      padding: 700px 0 900px 0;
    }
    @media screen and (max-height: 450px) {
      padding: 700px 0 900px 0;
    }
    .successAlert {
      position: absolute;
      width: 300px;
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      background-color: rgb(20, 20, 20);
      border-radius: 10px;
      font-weight: 700;
      padding: 10px;
      text-align: center;
      z-index: 1000;
      font-size: 17px;
      animation: fadeIn 0.3s ease-in-out;
      @media screen and (max-width: 1550px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
      }
      button {
        background-color: #fff;
        border: 0;
        outline: none;
        font-size: 17px;
        text-transform: uppercase;
        padding: 10px 13px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        color: #353180;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        transition: all 0.3s;
        &:nth-child(4) {
          margin-bottom: 25px;
        }
        @media screen and (max-width: 1650px) {
          font-size: 13px;
        }
        &:hover:not(:disabled) {
          box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
        }
        &:disabled {
          opacity: 0.5;
          cursor: initial;
        }
      }
    }
    .clickAway {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
  .text {
    width: 50%;
    position: relative;
    @media screen and (max-width: 800px) {
      width: 100%;
      text-align: center;
      padding: 5px 21px;
    }
    @media screen and (max-width: 425px) {
      padding: 5px 0;
    }
    .underTitle {
      text-transform: uppercase;
      font-family: "Kanit", sans-serif;
      font-weight: 400 !important;
      line-height: 40px !important;
      font-size: 40px;
      margin-top: 0;
      @media screen and (max-width: 1550px) {
        font-size: 30px;
        line-height: 30px !important;
      }
      @media screen and (max-width: 1350px) {
        font-size: 30px;
        line-height: 30px !important;
      }
      @media screen and (max-width: 1000px) {
        font-size: 22px;
        line-height: 20px !important;
      }
    }
    .timerContainer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 40px;
      margin-left: 2px;
      margin-bottom: 20px;
      -webkit-text-size-adjust: none;
      @media screen and (max-width: 1600px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 25px;
      }
      @media screen and (max-width: 800px) {
        justify-content: center;
      }
      @media screen and (max-width: 375px) {
        font-size: 20px;
      }
      span {
        -webkit-text-size-adjust: none;
        font-size: 20px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        line-height: 40px;
        margin: 0 15px;
        @media screen and (max-width: 1600px) {
          font-size: 15px;
          line-height: 30px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 12px;
          line-height: 20px;
        }
        @media screen and (max-width: 375px) {
          font-size: 10px;
        }
        &::first-line {
          font-size: 50px;
          @media screen and (max-width: 1600px) {
            font-size: 40px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 30px;
          }
          @media screen and (max-width: 375px) {
            font-size: 25px;
          }
        }
      }
    }
    .nexoCard {
      background: url("../../assets/Banner2.png") no-repeat;
      background-size: 100% auto;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      height: 50px;
      width: 450px;
      border-radius: 20px;
      box-shadow: 0px 10px 30px 2px rgba(0, 0, 0, 0.164);
      margin-left: 20px;
      @media screen and (min-width: 800px) and (max-width: 1000px) {
        margin-top: 80px;
      }
      @media screen and (min-width: 1001px) {
        margin-top: 110px;
      }
      @media screen and (max-width: 1350px) {
        margin-top: 95px;
      }
      @media screen and (max-width: 800px) {
        margin-top: 20px !important;
      }
      @media screen and (max-width: 1440px) {
        width: 400px;
        height: 45px;
      }
      @media screen and (max-width: 1000px) {
        width: 350px;
        height: 40px;
      }
      @media screen and (max-width: 800px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
        height: 33px;
        background-size: 100% 100%;
        column-gap: 0px;
      }
      p {
        margin: 0;
      }
      img {
        height: 50%;
        @media screen and (max-width: 425px) {
          margin-left: 5px;
        }
      }
    }
    .hint {
      font-style: italic;
      font-size: 16px;
      margin-top: 30px;
      // @media screen and (min-width: 800px) and (max-width: 1000px) {
      //   margin-top: 80px;
      // }
      // @media screen and (min-width: 1001px) {
      //   margin-top: 110px;
      //   width: 60%;
      // }
      // @media screen and (max-width: 1550px) {
      //   font-size: 14px;
      // }
      // @media screen and (max-width: 1350px) {
      //   font-size: 12px;
      //   margin-top: 95px;
      // }
      // @media screen and (max-width: 800px) {
      //   margin-top: 20px;
      // }
    }
    .title {
      position: relative;
      padding: 10px 0;
      margin-bottom: 10px;
      @media screen and (max-width: 1550px) {
        padding: 7px 25px;
      }
      @media screen and (max-width: 800px) {
        position: absolute;
        top: -480px;
        padding: 5px;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
      }
      @media screen and (max-width: 750px) {
        top: -430px;
      }
      @media screen and (max-width: 650px) {
        top: -385px;
      }
      @media screen and (max-width: 560px) {
        top: -350px;
      }
      @media screen and (max-width: 500px) {
        top: -300px;
      }
      // @media screen and (max-width: 470px) {
      //   top: -500px;
      // }
      // @media screen and (max-width: 426px) {
      //   top: -495px;
      // }
      // @media screen and (max-width: 376px) {
      //   top: -450px;
      // }
      @media screen and (max-width: 400px) {
        top: -260px;
      }
      h2 {
        text-transform: uppercase;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
        padding: 10px 0 0 20px;
        @media screen and (max-width: 1550px) {
          font-size: 45px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 30px;
          line-height: 30px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 25px;
          padding: 5px;
          line-height: 25px;
        }
      }
      img {
        position: absolute;
        @media screen and (max-width: 1024px) {
          width: 30px;
        }
        &:nth-child(1) {
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          bottom: 0;
          right: 30%;
          @media screen and (max-width: 1920px) {
            right: 18%;
          }

          @media screen and (max-width: 1860px) {
            right: 0;
          }
        }
      }
    }
    p {
      margin: 20px 0 30px 20px;
      font-size: 17px;
      @media screen and (max-width: 1550px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
      }
      @media screen and (max-width: 1000px) {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 800px) {
        margin-left: 0px;
      }
    }
    p:not(.hint) {
      font-weight: 700;
      line-height: 25px;
    }
    .buttons {
      margin-left: 20px;
      @media screen and (max-width: 800px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
      }
    }
    button {
      border: 0;
      outline: none;
      font-family: "Kanit", sans-serif;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      float: left;
      border-radius: 15px;
      column-gap: 10px;
      height: 55px;
      transition: all 0.4s;
      @media screen and (max-width: 1550px) {
        font-size: 16px;
        height: 50px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
        height: 40px;
        padding: 10px;
        width: 170px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 13px;
        height: 40px;
        padding: 0px;
        width: 170px;
      }
      @media screen and (max-width: 820px) {
        column-gap: 5px;
        font-size: 11px;
        height: 40px;
        padding: 8px;
        width: 160px;
        padding: 5px;
      }
      img {
        filter: invert(25%) sepia(42%) saturate(2685%) hue-rotate(226deg)
          brightness(30%) contrast(94%);
        height: 100%;
        @media screen and (max-width: 1000px) {
          height: 60%;
          width: auto;
          margin-right: 5px;
        }
        @media screen and (max-width: 800px) {
          height: 60%;
          width: 27px;
        }
      }
      &:nth-child(1) {
        margin-right: 15px;
        color: rgb(58, 54, 131);
        background-color: #fff;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
        }
      }
      &:nth-child(2) {
        background: transparent;
        border: 3px solid #fff;
        color: #fff;
        opacity: 0.6;
      }
    }
  }
  .preview {
    width: 50%;
    height: 23vw;
    padding: 15px;
    @media screen and (max-width: 1600px) {
      height: 20vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 1024px) {
      height: 25vw;
    }
    @media screen and (max-width: 800px) {
      width: 100%;
      height: 50vw;
    }
    @media screen and (max-width: 600px) {
      padding: 15px 0;
    }
    @media screen and (max-width: 425px) {
    }
    & > div {
      position: relative;
      box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5),
        0 0 50px 50px rgba(0, 0, 0, 0.5) inset;
      height: 100%;
      width: calc(23vw / 0.5999270868);
      @media screen and (max-width: 1600px) {
        width: calc(20vw / 0.6199270868);
      }
      @media screen and (max-width: 1024px) {
        width: calc(25vw / 0.6259270868);
      }
      @media screen and (max-width: 800px) {
        width: calc(50vw / 0.6599270868);
      }
    }
  }
}
.fadeOut {
  animation: fadeout 0.5s;
  opacity: 0;
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

iframe {
  #player {
    box-shadow: 0px 0px 10px 10px #000;
  }
}

.App {
  width: 100vw;
  height: 100vh;
  background: url("./assets/Main_BG_JP.jpg") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 500px) {
    background-size: auto 100%;
    text-align: center;
    background-repeat: repeat;
  }
  main {
    width: 100vw;
    padding: 0 200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 1440px) {
      width: 100vw;
      padding: 0;
    }
    footer {
      position: absolute;
      width: 100vw;
      height: 230px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #141623;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 17px;
      padding: 10px 70px;
      color: #fff;
      @media screen and (max-width: 768px) {
        //flex-direction: column;
        height: 520px;
        justify-content: flex-start;
        padding: 10px 20px 60px 20px;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 1550px) {
        font-size: 15px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 13px;
      }
      & > div {
        img {
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
        &:nth-child(1) {
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          row-gap: 25px;
          @media screen and (max-width: 1000px) {
            img {
              width: 30px;
            }
          }
          @media screen and (max-width: 768px) {
            order: 2;
            align-items: center;
            width: 50%;
            height: 50%;
            padding-left: 30px;
          }
          div {
            display: flex;
            column-gap: 15px;
          }
          a {
            text-decoration: none;
            color: #fff;
          }
        }
        &:nth-child(2) {
          height: 100%;
          width: 45%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 25px;
          @media screen and (max-width: 768px) {
            width: 100%;
            order: 1;
            height: 50%;
          }
          hr {
            width: 90%;
            background-color: #181c34;
            border: 1.5px solid #181c34;
          }
          div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            @media screen and (max-width: 768px) {
              padding-left: 20px;
            }
            img {
              @media screen and (max-width: 1600px) {
                width: 130px;
              }
              @media screen and (max-width: 768px) {
                width: 30%;
              }
            }
            a {
              @media screen and (max-width: 1600px) {
                width: 130px;
              }
              @media screen and (max-width: 768px) {
                width: 30%;
              }
              img {
                width: 100%;
              }
            }
          }
        }
        &:nth-child(3) {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 20px;
          @media screen and (max-width: 1000px) {
            margin-bottom: 83px;
            img {
              width: 30px;
            }
          }
          @media screen and (max-width: 768px) {
            order: 3;
            width: 50%;
            color: rgba(255, 255, 255, 0);
            font-size: 1px;
            margin-bottom: 83px;
            justify-content: flex-start;
            padding-left: 30px;
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    height: auto;
    color: white;
    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }
  }
}
.featured {
  background-color: #211d4b56;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.15),
    0px 0px 4px 2px rgba(0, 0, 0, 0.15) inset;
  width: 100vw;
  height: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-wrap: wrap;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    height: auto;
  }
  button {
    border: 0;
    outline: none;
    font-family: "Kanit", sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    float: left;
    border-radius: 15px;
    column-gap: 10px;
    height: 55px;
    transition: all 0.4s;
    @media screen and (max-width: 1550px) {
      font-size: 16px;
      height: 50px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
      height: 40px;
      padding: 10px;
      width: 170px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
      height: 40px;
      padding: 0px;
      width: 170px;
    }
    @media screen and (max-width: 820px) {
      column-gap: 5px;
      font-size: 11px;
      height: 40px;
      padding: 8px;
      width: 160px;
      padding: 5px;
    }
    img {
      filter: invert(25%) sepia(42%) saturate(2685%) hue-rotate(226deg)
        brightness(30%) contrast(94%);
      height: 100%;
      @media screen and (max-width: 1000px) {
        height: 60%;
        width: auto;
        margin-right: 5px;
      }
      @media screen and (max-width: 800px) {
        height: 60%;
        width: 27px;
      }
    }
    &:nth-child(1) {
      margin-right: 15px;
      color: rgb(58, 54, 131);
      background-color: #fff;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
      }
    }
    &:nth-child(2) {
      background: transparent;
      border: 3px solid #fff;
      color: #fff;
      opacity: 0.6;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    font-weight: 700;
    font-size: 19px;
    margin: 0 20px;
    @media screen and (max-width: 1550px) {
      font-size: 17px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
      margin: 20px 0;
    }
    img {
      @media screen and (max-width: 425px) {
        height: 30px;
      }
    }
  }
}
.announcement {
  width: 85%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #211d4b86;
  border-radius: 50px;
  margin: 20px auto;
  flex-wrap: wrap;
  padding: 25px 40px;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
    0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
  font-size: 17px;
  @media screen and (max-width: 1550px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1440px) {
    width: 90%;
    padding: 25px 20px;
  }
  @media screen and (max-width: 1350px) {
    font-size: 13px;
  }
  @media screen and (max-width: 900px) {
    width: 60%;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  img {
    width: 18%;
    @media screen and (max-width: 900px) {
      width: 80%;
    }
    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }
  & > div {
    &:nth-child(2) {
      width: 60%;
      @media screen and (max-width: 900px) {
        width: 80%;
      }
      @media screen and (max-width: 600px) {
        width: 95%;
      }
      p {
        margin: 15px 0;
      }
    }
    &:nth-child(3) {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      @media screen and (max-width: 900px) {
        width: 80%;
      }
      @media screen and (max-width: 600px) {
        width: 95%;
      }
      img {
        width: 60%;
        margin: 30px 0;
      }
      button {
        width: 100%;
        background-color: #fff;
        border: 0;
        outline: none;
        font-size: 17px;
        text-transform: uppercase;
        padding: 10px 13px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        color: #353180;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        transition: all 0.3s;
        &:nth-child(4) {
          margin-bottom: 25px;
        }
        @media screen and (max-width: 1650px) {
          font-size: 13px;
        }
        &:hover {
          box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
        }
      }
      a {
        color: #fff;
      }
    }
  }
}
.giveaway {
  width: 92%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #211d4b86;
  border-radius: 50px;
  margin: 60px auto 20px auto;
  flex-wrap: wrap;
  padding: 25px 40px;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
    0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
  @media screen and (max-width: 1440px) {
    width: 80%;
    padding: 25px 20px;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
    justify-content: center;
    text-align: center;
  }
  @media screen and (max-width: 520px) {
    width: 90%;
  }
  & > img {
    width: 35%;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    @media screen and (max-width: 425px) {
      width: 95%;
    }
  }
  & > div {
    width: 65%;
    @media screen and (max-width: 768px) {
      width: 85%;
    }
    @media screen and (max-width: 425px) {
      width: 95%;
    }
    img {
      width: 45%;
      transition: all 0.2s;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      @media screen and (max-width: 425px) {
        width: 85%;
      }
      &:hover {
        transform: scale(1.03);
      }
    }
    h2 {
      font-family: "Kanit", sans-serif;
      font-weight: 900;
      font-size: 32px;
      @media screen and (max-width: 1550px) {
        font-size: 28px;
      }
      @media screen and (max-width: 1130px) {
        font-size: 22px;
      }
      @media screen and (max-width: 890px) {
        font-size: 19px;
      }
      @media screen and (max-width: 768px) {
        font-size: 21px;
      }
    }
    p {
      width: 90%;
      margin: 30px 0;
      font-size: 19px;
      font-weight: 700;
      @media screen and (max-width: 1550px) {
        font-size: 17px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 15px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
aside {
  z-index: 999;
}
.bn-onboard-custom
  .bn-onboard-modal-content
  .svelte-rntogh
  .bn-onboard-dark-mode {
  z-index: 999;
}

.container {
  width: 100%;
  height: 40vw;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  @media screen and (max-width: 1700px) {
    height: 50vw;
  }
  @media screen and (max-width: 1350px) {
    width: 95%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1150px) {
    height: 650px;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    flex-direction: column;
    padding-bottom: 100px;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
  .text {
    height: auto;
    padding: 25px 40px;
    width: 52%;
    background-color: #211d4b86;
    border-radius: 25px;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
      0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
    @media screen and (max-width: 1150px) {
      width: 55%;
    }
    @media screen and (max-width: 1000px) {
      width: 60%;
      text-align: center;
      padding: 20px 21px;
      margin-bottom: 50px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 780px) {
      width: 100%;
    }
    .title {
      position: relative;
      padding: 10px 0;
      margin-bottom: 10px;
      @media screen and (max-width: 1550px) {
        padding: 7px 25px;
      }
      @media screen and (max-width: 1000px) {
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      h2 {
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
        padding: 5px 0 0 20px;
        @media screen and (max-width: 1550px) {
          font-size: 45px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 30px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 25px;
          padding: 5px;
          line-height: 25px;
        }
      }
      img {
        position: absolute;
        @media screen and (max-width: 1000px) {
          width: 30px;
        }
        &:nth-child(1) {
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          bottom: -5px;
          left: 88%;
          @media screen and (max-width: 1000px) {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }
  hr {
    width: 30%;
    background-color: #fff;
    @media screen and (max-width: 1000px) {
      margin: 0 auto;
    }
  }
  p {
    -webkit-text-size-adjust: none;
    margin: 12px 0;
    font-size: 17px;
    @media screen and (max-width: 1550px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
    }
  }
  .preview {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 92%;
    @media screen and (max-width: 1150px) {
      width: 45%;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      height: 350px;
      justify-content: center;
    }
    .select {
      height: 100%;
      width: 15%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @media screen and (max-width: 1150px) and (min-width: 1000px) {
        height: 80%;
        width: 25%;
      }
      @media screen and (max-width: 1000px) {
        width: 10%;
        height: 100%;
      }
      @media screen and (max-width: 425px) {
        width: 20%;
      }
      .line {
        position: absolute;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.8;
      }
      .cardImage {
        z-index: 3;
        height: calc(100% / 6);
        cursor: pointer;
        transition: all 0.3s;
        &:hover:not(.selected) {
          transform: scale(1.05);
          filter: brightness(80%);
        }
        &.selected {
          filter: brightness(55%);
        }
      }
    }
    .video {
      height: 31vw;
      width: 31vw;
      position: relative;
      overflow: hidden;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 255, 0.322);
      @media screen and (max-width: 1000px) {
        height: 40vw;
        width: 40vw;
      }
      @media screen and (max-width: 768px) {
        width: 50vw;
        height: 50vw;
      }
      @media screen and (max-width: 425px) {
        width: 70vw;
        height: 70vw;
      }
      .layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        top: 0;
        left: 0;
        z-index: 5;
      }
    }
  }
}

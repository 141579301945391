.container {
  background-position: center;
  width: 100%;
  padding: 0 200px;
  height: 130px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  z-index: 100;
  @media screen and (max-width: 1450px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 1100px) {
    height: 80px;
  }
  & > div {
    width: 100%;
    height: 95%;
    backdrop-filter: blur(10px);
    background-color: rgba(5, 14, 78, 0.589);
    border-radius: 0 0 25px 25px;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
      0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 40px;
    @media screen and (max-width: 670px) {
      padding: 0 10px;
    }
    &.long {
      @media screen and (max-width: 670px) {
        height: auto;
        padding: 21px 10px;
      }
    }
    .mobileNavList {
      width: 100%;
      height: 400px;
      display: none;
      div {
        height: calc(100% / 6);
        color: #fff;
        text-transform: uppercase;
        font-family: "Kanit", sans-serif;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
      &.opened {
        @media screen and (max-width: 670px) {
          display: block;
          animation: height 0.3s;
        }
      }
    }
    .logo {
      height: 55%;
      @media screen and (max-width: 1100px) {
        width: 85px;
        height: auto;
      }
      @media screen and (max-width: 376px) {
        width: 80px;
      }
      @media screen and (max-width: 321px) {
        width: 70px;
      }
    }
    .menu {
      display: none;
      width: 50px;
      @media screen and (max-width: 670px) {
        display: block;
      }
    }
    .navList {
      display: flex;
      @media screen and (max-width: 670px) {
        display: none;
      }
      & > div {
        &:not(:last-child) {
          border-right: 1px solid #fff;
        }
        &:hover p {
          text-shadow: 0px 1px 15px rgba(255, 255, 255, 0.24);
        }
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        cursor: pointer;
        user-select: none;
        @media screen and (max-width: 1100px) {
          padding: 5px 10px;
        }
        @media screen and (max-width: 880px) {
          padding: 3px 5px;
        }
        p {
          transition: all 0.5s;
          text-align: center;
          text-transform: uppercase;
          font-family: "Kanit", sans-serif;
          font-weight: 700;
          color: #fff;
          line-height: 18px;
          font-size: 17px;
          @media screen and (max-width: 1100px) {
            font-size: 15px;
            line-height: 14px;
          }
          @media screen and (max-width: 880px) {
            font-size: 12px;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            @media screen and (max-width: 1100px) {
              font-size: 11px;
            }
            @media screen and (max-width: 880px) {
              font-size: 10px;
            }
            // @media screen and (max-width: 768px) {
            //   font-size: 9px;
            // }
          }
        }
      }
    }
    .socialIcons {
      height: 40px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 10px 15px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      @media screen and (max-width: 880px) {
        height: 30px;
        padding: 7px 10px;
        column-gap: 0;
      }
      a:nth-child(1),
      a:nth-child(2),
      a:nth-child(3) {
        @media screen and (max-width: 880px) {
          margin-right: 10px;
        }
        @media screen and (max-width: 340px) {
          margin-right: 5px;
        }
      }
      img {
        height: 20px;
        cursor: pointer;
        transition: all 0.3s;
        @media screen and (max-width: 880px) {
          height: 15px;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
@keyframes height {
  0% {
    height: 0px;
  }
  100% {
    height: 400px;
  }
}

.container {
  width: 100%;
  height: auto;
  padding: 50px 0 200px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media screen and (max-width: 1550px) {
    padding-top: 25px;
  }
  @media screen and (max-width: 1000px) {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
  .title {
    width: fit-content;
    position: relative;
    padding: 10px 40px;
    margin-bottom: 50px;
    @media screen and (max-width: 1550px) {
      padding: 7px 25px;
      margin-bottom: 30px;
    }
    h2 {
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      font-size: 50px;
      text-transform: uppercase;
      @media screen and (max-width: 1550px) {
        font-size: 45px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 30px;
        line-height: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 25px;
        padding: 5px;
        line-height: 25px;
      }
    }
    img {
      position: absolute;
      &:nth-child(2) {
        top: 0;
        left: 0;
        @media screen and (max-width: 1350px) {
          width: 25px;
        }
      }
      &:nth-child(3) {
        bottom: 0;
        right: 0;
        @media screen and (max-width: 1350px) {
          width: 25px;
        }
      }
    }
  }
  .gridsContainer {
    width: 100%;
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .questsGrid {
    width: 50%;
    row-gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .qnaContainer {
      width: 98%;
      z-index: 1;
      margin-bottom: 10px;
      @media screen and (max-width: 768px) {
        text-align: left;
      }
      .question {
        background-color: #1d2761e7;
        position: relative;
        border-radius: 25px;
        box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
          0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 30px 15px 70px;
        cursor: pointer;
        z-index: 99;
        & > span {
          position: absolute;
          right: 4%;
          font-size: 30px;
          font-weight: 600;
        }
        p {
          font-size: 17px;
          position: relative;
          @media screen and (max-width: 1550px) {
            font-size: 15px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 13px;
          }
          span {
            position: absolute;
            left: -45px;
            top: 50%;
            transform: translateY(-50%);
            font-family: "Rubik", sans-serif;
            font-size: 35px;
            font-weight: 700;
            margin-right: 10px;
            @media screen and (max-width: 1550px) {
              font-size: 25px;
            }
          }
        }
      }
      .answer {
        z-index: 2;
        background-color: #1d276188;
        border-radius: 25px;
        box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
          0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 60px 30px 15px 70px;
        cursor: pointer;
        margin-top: -50px;
        overflow: hidden;
        @media screen and (max-width: 768px) {
          margin-top: -40px;
        }
        p {
          font-size: 17px;
          position: relative;
          @media screen and (max-width: 1550px) {
            font-size: 15px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 13px;
          }
          span {
            opacity: 0.5;
            position: absolute;
            left: -45px;
            top: 50%;
            transform: translateY(-50%);
            font-family: "Rubik", sans-serif;
            font-size: 35px;
            font-weight: 700;
            margin-right: 10px;
            @media screen and (max-width: 1550px) {
              font-size: 25px;
            }
          }
        }
        a {
          color: #fff;
        }
      }
    }
  }
}
